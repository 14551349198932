import React from 'react'
import tw from 'twin.macro'

import Layout from '../components/layout/Layout'
import SEO from '../components/seo/SEO'
import PageHeader from '../components/styled/PageHeader'
import Spinner from '../components/styled/Spinner'

const Menu = () => (
  <Layout>
    <SEO title='Menu' />
    <PageHeader>Latest Menu</PageHeader>
    <a
      css={tw`text-2xl font-semibold underline`}
      href='./pdfs/menu.pdf'
      target='_blank'
      rel='noreferrer'>
      View PDF Menu
    </a>
    <div className='sp-menu' css={tw`min-h-100 pt-6`}>
      <Spinner />
    </div>
  </Layout>
)

export default Menu
