import React from 'react'
import tw from 'twin.macro'

const Spinner = () => {
  return (
    <div
      className='loader'
      css={tw`scale-50 flex justify-center`}
      style={{ transform: 'scale(0.5)' }}>
      <div className='lds-spinner'>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default Spinner
